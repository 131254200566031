@import "../../assets/variable.scss";

@mixin collapseform {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  overflow: hidden;
  padding: 20px;
  background: #444;
  background: linear-gradient(#555, #2c2c2c);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5), 0 -1px 0 rgba(255, 255, 255, 0.4);
  color: white;
  border: none;
  border-radius: 5px;
}

@mixin mq768 {
  font-size: 15px;
  padding: 7px 5px 7px 8px;
}

@mixin animnopen {
  transform: translateY(-50%);
  display: none;
}
@mixin animclose {
  transform: translateY(-100%);
  height: 0px;
  color: transparent;
}

.coll {
  overflow: hidden;
  max-width: 1023px;
  margin: 10px 0 10px 0;
  padding: 0;
  width: 100%;
  @media (max-width: 768px) {
    margin: 5px 0 0px 0;
  }
  &_down {
    transform: rotate(0.5turn);
  }
  &_up,
  &_down {
    padding-left: 10px;
    padding-right: 10px;
    margin: 0;
    transition: transform 150ms;
    &:active {
      transform: rotate(0.5turn);
    }
    @media (max-width: 768px) {
      padding-left: 5px;
      padding-right: 5px;
    }
    img {
      @media (max-width: 768px) {
        width: 15px;
        width: 15px;
      }
    }
  }
  .skills_collapse {
    @include collapseform;
    font-size: 18px;
    font-weight: 300;
    padding: 15px 15px 15px 15px;
    &:active + .collapse_open {
      @include animnopen;
    }
    &:active + .collapse_close {
      @include animclose;
    }
    @media (max-width: 768px) {
      @include mq768;
      font-size: 12px;
    }
  }

  .collapse_open {
    list-style-type: none;
    padding-bottom: 20px;
    transform: translateY(0%);
    transition: 0.5s;
    opacity: 1;
    background-color: rgb(248, 248, 248);
    & > li {
      display: block;
      padding: 20px 10px 5px 0;
      font-size: 15px;
      font-weight: 400;
      @media (max-width: 768px) {
        font-size: 12px;
      }
    }
  }
  .collapse_close > li {
    transition: 0.5s;
    display: none;
  }
}
