@import "../../assets/variable.scss";

header {
  display: flex;
  flex-flow: row;
  justify-content: end;
  background-color: black;
  position: fixed;
  padding: 20px 20px 0px 30px;
  top: 0;
  left: 0;
  right: 0;
  @media (max-width: 1024px) {
    flex-direction: column;
  }
}

.navbar {
  margin: 0px 20px 20px 0px;
  @media (max-width: 1024px) {
    width: 200px;
    display: flex;
    align-items: end;
  }
  &_link {
    font-weight: 500;
    display: flex;
    flex-direction: row;
    text-align: center;
    padding: 15px 0;
    gap: 27px;
    @media (max-width: 1024px) {
      display: none;
    }
  }
}

.close_burger {
  font-weight: 500;
  display: flex;
  flex-direction: row;
  text-align: center;
  padding: 15px 0;
  gap: 27px;
  @media (max-width: 1024px) {
    display: flex;
    flex-direction: column;
    background: #48435c;
    font-size: 12px;
    height: 180px;
    width: 100px;
    margin-right: 300px;
    padding: 0;
    position: absolute;
    top: 60px;
    padding: 0;
    padding: 15px 0;
    gap: 2px;
  }
}

.label_burger {
  display: none;
  @media (max-width: 1024px) {
    width: 100px;
    height: 60px;
    display: flex;
    font-size: 40px;
    cursor: pointer;
  }
}

.login_btn,
.logout_btn {
  @media (max-width: 1024px) {
    display: none;
  }
}
