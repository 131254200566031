/* kaushan-script-regular - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Kaushan Script";
  font-style: normal;
  font-weight: 400;
  src: url("../assets/fonts/kaushan-script-v18-latin-regular.woff2")
    format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* work-sans-regular - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 400;
  src: url("../assets/fonts/work-sans-v19-latin-regular.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* inter-300 - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  src: url("../assets/fonts/inter-v13-latin-300.woff2") format("woff2");
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* inter-regular - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  src: url("../assets/fonts/inter-v13-latin-regular.woff2") format("woff2");
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* inter-800 - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Inter";
  font-style: normal;
  font-weight: 800;
  src: url("../assets/fonts/inter-v13-latin-800.woff2") format("woff2");
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

body {
  display: flex;
  justify-content: center;
  font-family: "Inter";
  background-position: center;
  background-size: cover;
  background-color: black;
  background-image: url("../assets/bckg1-min.webp");
  background-size: 105vw auto;
  background-position: 0% 0%;
  height: 100vh;
  width: 100vw;
  animation: horizontalMove 40s infinite;
  animation-timing-function: ease;
  color: white;
  @media (max-width: 768px) {
    background-size: auto;
  }
}

@keyframes horizontalMove {
  0% {
    background-position: 0% 0%;
  }
  50% {
    background-position: 100% 80%;
  }
  100% {
    background-position: 0% 0%;
  }
}
a {
  color: inherit;
  text-decoration: none;
}
main {
  padding: 20px 50px 20px 50px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 768px) {
    padding: 10px 20px 10px 20px;
  }
}

#root {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 0 100px 0 50px;
  box-sizing: border-box;

  @media (max-width: 768px) {
    padding: 0 20px 0 20px;
  }
  .page_title {
    margin-top: 150px;
    margin-bottom: 30px;
    @media (max-width: 768px) {
      margin-top: 120px;
      font-size: 24px;
    }
  }
}
