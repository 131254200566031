@import "../../assets/variable.scss";

.About {
  display: flex;
  flex-flow: column;
  max-width: 550px;
  width: 550px;
  margin-top: 250px;
  margin-bottom: 270px;
  @media (max-width: 768px) {
    width: 330px;
  }

  &_header {
    height: 90px;
    display: flex;
    flex-flow: row;
    align-items: center;
    width: 40em;
    margin-bottom: 10px;
    white-space: nowrap;
    overflow: hidden;
    animation: type 1s steps(60, end);

    @media (max-width: 768px) {
      width: 20em;
    }
  }
  &_picture {
    width: 60px;
    height: 60px;
    border-radius: 100%;
    margin-right: 20px;
    @media (max-width: 768px) {
      margin-right: 10px;
    }
  }
  &_name {
    font-weight: 600;
    color: white;
    font-size: 35px;
    @media (max-width: 768px) {
      font-size: 25px;
    }
  }
  &_title {
    font-weight: 500;
    font-size: 25px;

    @media (max-width: 768px) {
      font-size: 10px;
    }
  }
  &_btn {
    background: transparent;
    border: 1px solid $green-light;
    color: $green-light;
    padding: 10px;
    font-size: 14px;
    @media (max-width: 768px) {
      width: 310px;
    }
  }

  &_presentation {
    font-size: 15px;
    width: 580px;
    @media (max-width: 768px) {
      max-width: 330px;
      font-size: 13px;
    }
  }
  @keyframes type {
    from {
      width: 0;
    }
  }
}
