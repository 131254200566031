article {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

ul {
  color: rgb(67, 65, 65);
}

.gskills {
  display: flex;
  width: 100%;
  margin-top: 100px;
  margin-bottom: 50px;
  background-color: rgba(46, 125, 50, 0.3);
  border-radius: 10px;
  padding: 20px;
  @media (max-width: 1024px) {
    flex-direction: column;
    align-items: center;
  }

  &_category {
    padding: 10px;
    width: 90%;
    font-size: 18px;
    font-weight: 700;
    @media (max-width: 768px) {
      font-size: 15px;
      width: 100%;
    }
  }
}
