@import "../../assets/variable.scss";

.contenairfooter {
  background-color: black;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  padding: 25px 25px;
  width: 100%;
  margin-top: 20px;

  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
  &_text {
    color: white;
    font-size: 15px;
    font-weight: 300;

    @media (max-width: 768px) {
      font-size: 12px;
      padding-top: 10px;
    }
  }
  &_link {
    color: white;
    font-size: 15px;
    font-weight: 300;

    a {
      padding: 0 20px;
    }
  }

  .button_cv {
    background-color: white;
    color: black;
    font-weight: 800;
    border-radius: 100%;
    padding: 5px;
    margin: 25px;
    @media (max-width: 768px) {
    }
  }
}
