@import "../../assets/variable.scss";

.contact {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 0px;
  margin-bottom: 30px;
  width: 100%;
}
.form_contenair {
  background-color: rgba(46, 125, 50, 0.3);

  padding: 2em;
  margin-top: 0px;
  @media (max-width: 768px) {
    padding: 1em;
    font-size: 18px;
    width: 100%;
  }
}

form {
  text-align: center;
  margin: 10px auto;
  width: 600px;
  @media (max-width: 768px) {
    width: 90%;
  }
}

.info_mail {
  color: rgb(191, 187, 187);
  font-size: 15px;
  font-style: italic;
}
