.my_name {
  display: flex;
  flex-direction: column;
  font-family: "Kaushan Script";
  font-size: 20px;
  font-weight: 800;
  position: fixed;

  top: 30px;
  left: 30px;
  @media (max-width: 1024px) {
    position: fixed;
    left: 80px;
    top: 38px;
  }
}
.my_name > span {
  font-family: "Work Sans";
  font-size: 15px;
  letter-spacing: 0.05em;
  font-weight: normal;
}
