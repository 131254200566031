@import "../../assets/variable.scss";

.skills {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  background-color: rgba(241, 247, 241, 0.3);
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 10px;
  margin-top: 12px;

  width: 100%;
  @media (max-width: 1024px) {
    flex-direction: column;
    margin: 0;
  }

  &_details {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    padding: 8px;
  }

  &_bar {
    width: 100%;
  }

  &_category {
    padding: 10px;
    width: 100%;
    font-weight: 300;
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    & > span {
      border-radius: 10px;
      margin-bottom: 10px;
      text-align: center;
      color: white;
      padding: 20px;
      background: #444;
      background: linear-gradient(#555, #2c2c2c);
      text-shadow: 0px 1px 0px white;
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.5), 0 -1px 0 rgba(255, 255, 255, 0.4);
      @media (max-width: 768px) {
        padding: 8px;
      }
    }
    &_item {
      font-weight: 500;
      font-size: 15px;
      margin: 5px;
      display: flex;
      line-height: 1;
      height: 20px;

      @media (max-width: 1024px) {
        font-size: 12px;
        margin: 3px;
      }
    }
  }
}

.logo_skill {
  margin-left: 2px;
  @media (max-width: 768px) {
    width: 20px;
  }
}
