@import "../../assets/variable.scss";

.label_work {
  background-color: rgba(46, 125, 50, 0.3);
  border-radius: 3px;
  margin: 10px 20px;
  position: relative;
  padding: 5px;
  left: 0px;
  top: 15px;
  font-weight: 500;
  font-size: 18px;
  @media (max-width: 768px) {
    font-size: 12px;
    font-weight: 700;
    background-color: rgba(46, 125, 50, 0.81);
  }
}

.gallery_img {
  max-width: 768px;
  width: 768px;
  height: 500px;
  @media (max-width: 768px) {
    width: 300px;
    height: 200px;
  }
}

.gallery {
  margin-top: 0px;
  margin-bottom: 0px;

  max-width: 1440px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 768px) {
    margin-top: 20px;
    margin-bottom: 0px;
  }
}
