@import "../../assets/variable.scss";
.work_details {
  margin-top: 80px;
  margin-bottom: 200px;
  margin: 180px 10px 200px 10px;
  display: flex;
  @media (max-width: 1024px) {
    margin: 150px 20px 200px 30px;
  }
}
.work {
  display: flex;
  margin: 0px 0 250px 0;
  @media (max-width: 1024px) {
    flex-flow: column wrap;
    align-items: center;
    margin: 10px;
    justify-content: space-between;
  }

  &_img {
    padding-top: 30px;
    @media (max-width: 768px) {
      padding-top: 0px;
    }
  }
  &_tools {
    background-color: #198754;
    font-size: 12px;
    font-weight: 700;
    padding: 5px;
    margin: 3px;
    border-radius: 8px;
    @media (max-width: 768px) {
      font-size: 10px;
    }
  }
  .tools_contenair {
    display: flex;
    flex-flow: row wrap;
  }
  &_title {
    font-size: 25px;
    padding-bottom: 10px;
    @media (max-width: 768px) {
      font-size: 18px;
    }
  }
  &_obj {
    font-size: 15px;
    font-style: Italic;
    font-weight: 300;
    max-width: 650px;
    padding: 20px 0;
    @media (max-width: 768px) {
      font-size: 12px;
    }
  }
  &_skills {
    font-size: 15px;
    font-style: normal;
    max-width: 600px;
    color: white;
    @media (max-width: 768px) {
      font-size: 12px;
    }
  }
}

.linkform {
  color: black;
  font-size: 12px;
  font-weight: 700;
  margin-top: 15px;
  &_git {
    background-color: #e2ad0d;

    padding: 5px;
    margin: 3px;
    border-radius: 8px;
  }
  &_web {
    background-color: #0dcaf0;

    padding: 5px;
    margin: 3px;
    border-radius: 8px;
  }
}
